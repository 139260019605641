var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"clav-info-content",attrs:{"flat":""}},[_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"font-weight-medium",attrs:{"append-icon":"search","label":"Procurar pedido","text":"","single-line":"","hide-details":"","clearable":"","color":"primary"},model:{value:(_vm.procurar),callback:function ($$v) {_vm.procurar=$$v},expression:"procurar"}},on))]}}])},[_c('span',[_vm._v(" Procurar pedido")])]),_c('v-data-table',{attrs:{"loading":!_vm.pedidos,"loading-text":"A carregar... Por favor aguarde","search":_vm.procurar,"headers":_vm.headers,"items":_vm.pedidos,"footer-props":_vm.footer_props,"page":_vm.paginaTabela,"sort-by":"data","sort-desc":""},on:{"update:page":function($event){_vm.paginaTabela=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"font-weight-medium ma-2",attrs:{"border":"left","type":"warning","icon":"warning"}},[_vm._v(" Não existem pedidos neste estado. ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"font-weight-medium ma-2",attrs:{"border":"left","type":"warning","icon":"warning"}},[_vm._v("Não foram encontrados resultados para \""+_vm._s(_vm.procurar)+"\".")])]},proxy:true},{key:"item.entidade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.entidade.split("_")[1])+" ")]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.split("T")[0])+" ")]}},{key:"item.tarefa",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.showPedido(item)}}},on),[_c('unicon',{attrs:{"name":"look-icon","width":"25","height":"25","viewBox":"0 0 20.71 15.574","fill":"#0D47A1"}})],1)]}}],null,true)},[_c('span',[_vm._v("Ver Pedido")])])],1),(
            _vm.temPermissaoDistribuir() &&
            (item.estado === 'Submetido') | (item.estado === 'Ressubmetido')
          )?_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('distribuir', item)}}},on),[_c('unicon',{attrs:{"name":"participacao-icon","width":"25","height":"25","viewBox":"0 0 20.71 17.678","fill":"#0D47A1"}})],1)]}}],null,true)},[_c('span',[_vm._v("Distribuir Pedido")])])],1):_vm._e(),(
            _vm.temPermissaoDevolver() &&
            (item.estado === 'Submetido' || item.estado === 'Ressubmetido')
          )?_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('devolver', item)}}},on),[_c('unicon',{attrs:{"name":"devolver-icon","width":"25","height":"25","viewBox":"0 0 20.71 12.943","fill":"#0D47A1"}})],1)]}}],null,true)},[_c('span',[_vm._v("Devolver Pedido")])])],1):_vm._e(),(
            _vm.temPermissaoAnalisar() &&
            (item.estado === 'Distribuído' || item.estado === 'Redistribuído')
          )?_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('analisar', item)}}},on),[_c('unicon',{attrs:{"name":"consultar-icon","width":"25","height":"25","viewBox":"0 0 20.71 20.697","fill":"#0D47A1"}})],1)]}}],null,true)},[_c('span',[_vm._v("Analisar Pedido")])])],1):_vm._e(),(
            _vm.temPermissaoValidar() &&
            (item.estado === 'Apreciado' || item.estado === 'Reapreciado')
          )?_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":""},on:{"click":function($event){return _vm.$emit('validar', item)}}},on),[_c('unicon',{attrs:{"name":"accept-icon","width":"25","height":"25","viewBox":"0 0 20.71 18.204","fill":"#0D47A1"}})],1)]}}],null,true)},[_c('span',[_vm._v("Validar Pedido")])])],1):_vm._e(),(
            _vm.temPermissaoValidar() &&
            (item.estado === 'Apreciado2v' || item.estado === 'Reapreciado2v')
          )?_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":""},on:{"click":function($event){return _vm.$emit('validar', item)}}},on),[_c('unicon',{attrs:{"name":"accept-icon","width":"25","height":"25","viewBox":"0 0 20.71 18.204","fill":"#0D47A1"}})],1)]}}],null,true)},[_c('span',[_vm._v("Validar Pedido")])])],1):_vm._e(),(_vm.temPermissaoAnalisar() && item.estado === 'Em Despacho')?_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"top":"","color":"info","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":""},on:{"click":function($event){return _vm.$emit('despachar', item)}}},on),[_c('unicon',{attrs:{"name":"pedido-despacho-icon","width":"25","height":"25","viewBox":"0 0 20.712 20.71","fill":"#0D47A1"}})],1)]}}],null,true)},[_c('span',[_vm._v("Despachar Pedido")])])],1):_vm._e()],1)]}},{key:"pageText",fn:function(ref){
          var props = ref.props;
return [_vm._v(" Pedidos "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }